exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-chile-chontal-jsx": () => import("./../../../src/pages/chile-chontal.jsx" /* webpackChunkName: "component---src-pages-chile-chontal-jsx" */),
  "component---src-pages-concursos-duelo-de-fogones-jsx": () => import("./../../../src/pages/concursos/duelo-de-fogones.jsx" /* webpackChunkName: "component---src-pages-concursos-duelo-de-fogones-jsx" */),
  "component---src-pages-concursos-index-jsx": () => import("./../../../src/pages/concursos/index.jsx" /* webpackChunkName: "component---src-pages-concursos-index-jsx" */),
  "component---src-pages-estado-solicitud-jsx": () => import("./../../../src/pages/estado-solicitud.jsx" /* webpackChunkName: "component---src-pages-estado-solicitud-jsx" */),
  "component---src-pages-festival-del-chocolate-jsx": () => import("./../../../src/pages/festival-del-chocolate.jsx" /* webpackChunkName: "component---src-pages-festival-del-chocolate-jsx" */),
  "component---src-pages-ficha-pago-jsx": () => import("./../../../src/pages/ficha-pago.jsx" /* webpackChunkName: "component---src-pages-ficha-pago-jsx" */),
  "component---src-pages-historia-del-chocolate-jsx": () => import("./../../../src/pages/historia-del-chocolate.jsx" /* webpackChunkName: "component---src-pages-historia-del-chocolate-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-origen-jsx": () => import("./../../../src/pages/origen.jsx" /* webpackChunkName: "component---src-pages-origen-jsx" */),
  "component---src-pages-programa-jsx": () => import("./../../../src/pages/programa.jsx" /* webpackChunkName: "component---src-pages-programa-jsx" */),
  "component---src-pages-reservaciones-jsx": () => import("./../../../src/pages/reservaciones.jsx" /* webpackChunkName: "component---src-pages-reservaciones-jsx" */),
  "component---src-pages-solicitud-expositores-jsx": () => import("./../../../src/pages/solicitud-expositores.jsx" /* webpackChunkName: "component---src-pages-solicitud-expositores-jsx" */),
  "component---src-pages-validate-jsx": () => import("./../../../src/pages/validate.jsx" /* webpackChunkName: "component---src-pages-validate-jsx" */)
}

