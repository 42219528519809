import React from "react";
import { Helmet } from "react-helmet";

const MetaPixel = () => {
  return (
    <Helmet>
      <script key="function" id="facebook-pixel-script">
        {`
!function(f,b,e,v,n,t,s)

{if(f.fbq)return;n=f.fbq=function(){n.callMethod?

n.callMethod.apply(n,arguments):n.queue.push(arguments)};

if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';

n.queue=[];t=b.createElement(e);t.async=!0;

t.src=v;s=b.getElementsByTagName(e)[0];

s.parentNode.insertBefore(t,s)}(window, document,'script',

'https://connect.facebook.net/en_US/fbevents.js');

fbq('init', '270650989130367');

fbq('track', 'PageView');
<!-- End Meta Pixel Code -->`}
      </script>

      {/*    <script type="text/javascript">
        {`(function(w,d,v3){
w.chaportConfig = {
appId : '652d5e9586daeee1b968c194'
};

if(w.chaport)return;v3=w.chaport={};v3._q=[];v3._l={};v3.q=function(){v3._q.push(arguments)};v3.on=function(e,fn){if(!v3._l[e])v3._l[e]=[];v3._l[e].push(fn)};var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://app.chaport.com/javascripts/insert.js';var ss=d.getElementsByTagName('script')[0];ss.parentNode.insertBefore(s,ss)})(window, document);`}
      </script>

      <noscript key="image" id="facebook-pixel-image">
        {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=270650989130367&ev=PageView&noscript=1" />`}
      </noscript> */}
    </Helmet>
  );
};

export default MetaPixel;
