import React from "react";
import { CssBaseline, Fab, ThemeProvider } from "@mui/material";
import { theme } from "./src/utils/themes";
import "./src/assets/sass/styles.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import MetaPixel from "./src/utils/MetaPixel";
import moment from "moment";
import "moment/dist/locale/es";

moment().locale("es");

export default ({ element }) => (
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MetaPixel />
      {element}
      <CssBaseline />
    </LocalizationProvider>
    <ToastContainer theme="dark" />
  </ThemeProvider>
);
